.radius-10{
	border-radius:10px;
}
.radius-20{
	border-radius:20px;
}
.radius-circle{
	border-radius:50%;
}
.margin-t40 {
	margin-top: 40px;
}
.margin-t30 {
	margin-top: 30px;
}
.mb30{
	margin-bottom: 30px;
}
.mb20{
	margin-bottom: 20px;
}
.mb10{
	margin-bottom: 10px;
}
.margin-t20 {
	margin-top: 20px;
}
.margin-t10 {
	margin-top: 10px;
}
.f14 {
    font-size: 14px;
}
.red-link{
	color:#FF0000;
}
.red-link:hover{
	color:#FF0000;
	opacity: 0.8;
}
.blue-link {
    color: #00acc0;
}
.blue-link:hover {
    color: #1f9ead;
}
.btn {
    box-shadow: unset!important;
}
button.btn.btn-transparent {
    border: 1px solid #000;
}
button.btn.btn-transparent:hover {
    background: #000;
    color: #fff;
}
.btn.btn-primary:hover {
    background: #db655a;
}
.btn.btn-primary {
    background: #E77469;
    border-color: #E77469;
}
.btn-dark:hover {
    background-color: #3c4248;
    border-color: #3c4248;
}
.btn.btn-default {
    background: #D9D9D9;
    border: 1px solid #D9D9D9;
}
.btn.btn-default:hover {
    background: #d3d2d2;
}
.btn.gray-transparent-btn:hover {
    background: rgba(0, 0, 0, 0.1);
    color: rgba(0,0,0,1);
}
.btn.gray-transparent-btn {
    border: 1px solid rgba(0, 0, 0, 0.4);
    color: rgba(0,0,0,0.6);
}
.btn-lg{
    padding: 13px 20px;	
}
.btn-md{
    padding: .375rem 20px;	
}
.full-btn {
    width: 100%;
}
.bg-gray {
	background: #D9CEB4;
}
.full-width-btn {
    width: 100%;
}
.download-btn {
    width: 55px;
    height: 55px;
    background: rgba(0, 172, 192, 0.59);
    border-radius: 12px;
    border-width: 0;
    color: #fff;
}
.download-btn:hover {
    background: rgba(0, 172, 192, 0.7);
}
.btn-gray{
    background: #f0f0f0;
}
.btn-gray:hover {
    background: #ededed;
}
.form-control {
	height: 60px;
    border-color: #ddd;
    border-radius: 0;
    outline: unset;
    box-shadow: unset;
    box-shadow: unset;
}
.form-control:focus {
    box-shadow: unset;
}
.btn.btn-success {
    background: #1E8400;
    border-color: #1E8400;
    width: 100px;
    border-radius: 10px;
}
.btn.btn-success:hover {
    background: #269107;
}
.table-action .btn.btn-success {
    background: #1E8400;
    border-color: #1E8400;
    width: 80px;
    border-radius: 4px;
    font-size: 12px;
    margin-left: auto;
}
.table-action .btn.btn-danger {
    background: #DA0000;
    border-color: #DA0000;
    width: 80px;
    border-radius: 4px;
    font-size: 13px;
    margin-left: auto;
}
.table-action .btn.btn-danger.reminder {
    width: 100%;
}
 .btn-suspend {
    background: #FFAA7A!important;
    border-color: #FFAA7A!important;
}
.form-control {
    box-shadow: unset!important;
    outline: 0!important;
}
/* Preeti- 19-04-23*/
.first-table span {
    display: grid;
}

button.btn.btn-Primary {
    background: #4A90E2;
    border-color: #4A90E2;
    width: 80px;
    border-radius: 4px;
    font-size: 12px;
    color: #ffff;
}

.table-user-king-icon img {
    margin: 0px 11px 0px;
}


/* Preeti- 20-04-23*/
button.btn.btn-success.user-btn {
    margin-bottom: 6px;
}

.premiumship ul.plan-point li {
    padding-left: 35px;
    position: relative;
    font-size: 14px;
    margin-bottom: 25px;
    text-align: left;
}
.premiumship ul.plan-point {
    list-style: none;
    padding: 17px 0px;
    color: #757575;
}

.premiumship h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 36px;
    width: 272px;
    height: 36px;
   
}

button.btn.btn-transparent.updatemember {
    width: 185px;
    height: 46px;
    margin-top: 23px;
}
button.btn.btn-dark.updatemember {
    width: 210px;
    height: 46px;
    margin-top: 23px;
    color: #fff;
   
}

#Premimum-Membership {
    padding: 2rem 4rem;
    text-align: center;
    min-height: 70vh;
    position: relative;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 10%);
}
.premiumship {
    margin-bottom: -25px;
}
.update-form label {
    float: left;
    color: #757575;
}
.update-form .form-control {
    height: 46px;
    color: #757575;
}

button.btn.btn-secondary.keywords-btn {
    border-color: #C7C7C7;
    margin: 0px 4px 0px;
    background: #C7C7C7;
    opacity: 0.6;
    border-radius: 17px;
    color:  #040404;
    font-weight: 500;
}
.Approved-popup h4 {
    font-size: 22px;
    font-weight: 600;
}
.Deny-popup h4 {
    font-size: 22px;
    font-weight: 600;
}
/* .profile-img-box {
    align-items: center;
    padding: 0;
    display: flex;
    flex: auto;
} */
.pteam-detail span {
    color: #757575;
    font-weight: 600;
    margin: 0px 5px 0px;
}

.team-view-table .following-table {
    /* width: 73%;
    padding: 6px;
    border-radius: 10px;
    outline-color: #484848;
    background: #484848;
    margin: 2rem 11rem; */
    width: 100%;
    padding: 12px 0;
    border-radius: 10px;
    outline-color: #484848;
    background: #484848;
    margin: 4rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

#team-table ul.following-table > li > button {
    width: 100%;
    border-radius: 0.5rem;
    font-size: 13px;
    padding: 10px 4px;
}

.team-view-table .following-table .nav-link {
    color: #fff;
}

.team-view-table .following-table li {
    /* width: 20%;
    text-align: center;
    font-size: 15px;
    padding: 0px; */
    width: 14%;
    text-align: center;
    font-size: 24px;
    padding: 0px;
}

.team-view-table .following-table .nav-link:focus, .team-view-table .following-table .nav-link:hover {
    isolation: isolate;
    border-color: transparent !important;
    /*    color: #fff;*/
}

.team-view-table .following-table .nav-link {
    color: #fff;
}

.restaurant_special_tab {
    margin-top: 0px !important;
}

.restaurant_special_tab ul.mb-3.nav.nav-tabs {
   /* margin: 2rem 17rem; */
   /* margin: 0rem 13rem; */
   justify-content: center;
}

.restaurant_special_tab .nav-link {
    font-size: 16px !important;
}

.choose-plan {
    width: 142px;
    height: 41px;
    margin-left: 5rem;
}
/* Preeti 25-04-23*/

.table-user-cross-icon img {
    margin: 0px 9px;
}

.pteam-detail li {
    display: flex;
    margin: 13px 0px;
    font-weight: 600;
    padding: 5px 0px;
}

.pteam-detail {
    margin-top: -2.9rem;
}

.view-img img {
    width: 100%;
    height:24% ;
    border-radius: 15px;
    max-width:82%;
}

.Sandwich-board {
    border-radius: 15px;
    box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 10%);
    padding: 30px;
    margin-top: 30px;
    border: 1px solid transparent;
    background: #198754;
    color: #fff;
}
.top-sandwitch{
    margin-bottom: 10px;
}
.sandwich-nav{
    margin: 0 auto;
}
.footer-sandwitch{
    text-align: center;
}
.footer-sandwitch {
    margin-top: 1rem;
    font-size: 18px;
    font-style: italic;
    font-weight: 700;
    background: #fff;
    color: #e9095c;
    padding: 10px;
}

.top-sandwitch {
    width: 100%;
    text-align: center;
    display: inline-block;
    font-size: 24px;
    font-weight: 600;
}

.Pdf-uploaded {
    text-align: center;
}

.side-nav-li .dropdown-toggle::after {
    display:none;
  
}